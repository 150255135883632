<template>
  <div class="container">
    <charts-container />
  </div>
</template>

<script>
import ChartsContainer from './components/ChartsContainer.vue';
export default {
  name: 'App',
  components: {
    ChartsContainer,
  },
};
</script>
<style>
* {
  font-family: 'Inter', sans-serif;
}
.form-control {
  border: none;
  background-color: #f2f2f2;
}
</style>
