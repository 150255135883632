<template>
  <div class="mb-4 pb-4">
    <h1 class="text-center mb-3">Ifeza charts</h1>
    <h3 class="text-center">Orders chart</h3>
    <div class="row text-center d-flex justify-content-center pb-5">
      <div class="col-md-4">
        <label>Order Status</label>
        <select v-model="status" class="form-control" @change="refreshData">
          <option value="">All Status</option>
          <option value="pending">Pending</option>
          <option value="expired">Expired</option>
          <option value="completed">Completed</option>
          <option value="delivered">Delivered</option>
        </select>
      </div>
      <div class="col-md-4">
        <label>From Date</label>
        <input
          @input="refreshData"
          v-model="fromDate"
          type="date"
          class="form-control"
        />
      </div>
      <div class="col-md-4">
        <label>To Date</label>
        <input
          @input="refreshData"
          v-model="toDate"
          type="date"
          class="form-control"
        />
      </div>
    </div>
    <div class="py-3">
      <apexchart
        type="area"
        height="550"
        :options="options"
        :series="series"
      ></apexchart>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import VueApexCharts from 'vue-apexcharts';
export default {
  name: 'OrdersChart',
  components: {
    apexchart: VueApexCharts,
  },
  data() {
    return {
      status: '',
      fromDate: '2022-02-14',
      toDate: '2022-03-28',
      series: [],
      options: {
        dataLabels: {
          enabled: false,
        },
        noData: {
          text: 'No Data',
          align: 'center',
          verticalAlign: 'middle',
          offsetX: 0,
          offsetY: 0,
          style: {
            fontSize: '20px',
            color: 'red',
          },
        },
        chart: {
          toolbar: {
            show: true,
            offsetX: 0,
            offsetY: 0,
            tools: {
              download: false,
              selection: true,
              zoom: true,
              zoomin: true,
              zoomout: true,
              pan: false,
              reset: false,
            },
            autoSelected: 'zoom',
          },
        },
        xaxis: {
          type: 'category',
        },
      },
    };
  },
  methods: {
    /**
    async method to fetch list orders made by corresponding dates(from-to) and order status
    also returns the collection of total prices with their timestamp
    */
    async getOrdersByDates() {
      const res = await axios.get(
        'https://test.ifeza.rwandabuildprogram.com/api/stats/orders',
        {
          params: {
            status: this.status,
            from: this.fromDate,
            to: this.toDate,
          },
        }
      );

      let data = res.data;
      let initialValue = 0;
      let totalPriceCollection = [];

      for (let item in data) {
        const totalPrice = data[item].reduce(function (prev, curVal) {
          return prev + curVal.total_cost;
        }, initialValue);

        totalPriceCollection.push({
          x: item,
          y: totalPrice,
        });
      }

      return totalPriceCollection;
    },

    /**
      async method that updates chart series obj with incoming data
    */

    async updateChartSeries() {
      this.series = [
        {
          type: 'area',
          name: 'total sold price',
          data: await this.getOrdersByDates(),
        },
      ];
    },

    /**
     reusable method that refresh data for chart
    */

    refreshData() {
      this.getOrdersByDates();
      this.updateChartSeries();
    },
  },

  created() {
    this.updateChartSeries();
  },
};
</script>

<style></style>
