<template>
  <div>
    <h3 class="text-center">Get most popular products</h3>
    <p class="text-center">
      products are ranked according to total orders made
    </p>
    <label>Pick top products number (total products : {{ arrLength }}) </label>
    <input
      type="number"
      v-model="range"
      @input="refreshData"
      min="1"
      :max="arrLength"
      class="form-control"
    />
    <h4 class="text-center py-3">Top : {{ range }}</h4>
    <apexchart type="donut" height="500" :series="series" :options="options" />
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';
import axios from 'axios';
export default {
  name: 'ProductsChart',
  components: {
    apexchart: VueApexCharts,
  },
  data() {
    return {
      range: 3,
      arrLength: 0,
      options: {
        series: [],
        labels: [],
        pie: {
          donut: {
            size: '65%',
          },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: 'bottom',
              },
            },
          },
        ],
      },
    };
  },
  methods: {
    /**
      async method to get list of popular products in ifeza api and instatiate to series data
    */
    async getPopularProducts() {
      const res = await axios.get(
        'https://test.ifeza.rwandabuildprogram.com/api/stats/popular-products'
      );

      let allData = [];
      const { data } = res;

      for (let item in data) {
        const totalQuantity = data[item].reduce((prev, curr) => {
          return prev + curr.quantity;
        }, 0);

        allData.push({
          label: item,
          total: totalQuantity,
        });
      }

      let sortedData = allData.sort((a, b) => {
        return b.total - a.total;
      });

      this.arrLength = sortedData.length;

      let labelRangeData = [];
      let seriesRangeData = [];

      if (this.range <= sortedData.length && this.range !== '') {
        if (this.range <= sortedData.length) {
          for (let i = 0; i < this.range; i++) {
            // range data
            labelRangeData.push(sortedData[i].label);
            seriesRangeData.push(sortedData[i].total);
          }
        }

        this.options = {
          series: seriesRangeData,
          labels: labelRangeData,
          pie: {
            donut: {
              size: '65%',
            },
          },
        };
      }
    },

    refreshData() {
      if (this.range !== '') {
        this.getPopularProducts();
      }
    },
  },
  created() {
    this.getPopularProducts();
  },
};
</script>

<style></style>
