<template>
  <div>
    <orders-chart />
    <products-chart />
  </div>
</template>

<script>
import OrdersChart from './OrdersChart.vue';
import ProductsChart from './ProductsChart.vue';
export default {
  components: { ProductsChart, OrdersChart },
};
</script>

<style></style>

ProductsChart
